var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"pa-6 ma-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"my-0",attrs:{"align-content":"center"}},[_c('v-card-title',{staticClass:"pt-0 ttext-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('sets.newSet'))+" ")])],1),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"my-4",style:({
          display: 'grid',
          'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
          'grid-template-rows': 'repeat(4, 1fr)',
          gap: '1rem',
        })},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"courier-logo",staticStyle:{"height":"128px","justify-content":"center"},attrs:{"type":"button"},on:{"click":function($event){return _vm.selectSpeditor({ code: 'CustomShipment' })}}},[_c('v-icon',{attrs:{"size":"34","color":"primary lighten-1"}},[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" "+_vm._s(_vm.$t('courierSet.differentShippingMethod'))+" ")],1),_vm._l((_vm.speditors),function(speditor){return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],key:_vm.$t(speditor.translationCode),staticClass:"courier-logo",attrs:{"type":"button"},on:{"click":function($event){return _vm.selectSpeditor(speditor)}}},[_c('v-img',{staticClass:"center",staticStyle:{"position":"absolute"},attrs:{"src":_vm.logo(speditor.code),"contain":"","max-height":"100%","max-width":"100%"}})],1)})],2)],1)],1),_c('v-dialog',{attrs:{"width":"650px"},on:{"click:outside":function($event){_vm.chargeInfoDialog.visible = false}},model:{value:(_vm.chargeInfoDialog.visible),callback:function ($$v) {_vm.$set(_vm.chargeInfoDialog, "visible", $$v)},expression:"chargeInfoDialog.visible"}},[_c('AdditionalCharge',{on:{"@add":function($event){return _vm.selectSpeditor(_vm.speditor)},"@close":function($event){_vm.speditor = {};
        _vm.chargeInfoDialog.visible = false;}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }